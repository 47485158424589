import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import BlogPostContent from "./blog-post-content"
import ShareBlock from "../components/share-block"

import "../css/typography.css"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.contentfulPost
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.title} description={post.subtitle || post.title} />
      <article>
        <header>
          {post.image && <Img fluid={post.image.fluid} />}
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
              color: "#3e5336",
              fontFamily: "Girassol",
            }}
          >
            {post.title}
          </h1>
          <div
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginTop: rhythm(1 / 2),
              marginBottom: rhythm(1),
              color: "#948f47",
              fontFamily: "Baloo Bhaina 2",
            }}
          >
            {post.category &&
              post.category.map(node => (
                <Link
                  style={{
                    boxShadow: `none`,
                    border: "1px solid #948f47",
                    borderRadius: "10px",
                    backgroundColor: "#948f47",
                    color: "#ffffff",
                    display: "inline-block",
                    padding: "1px 5px 0",
                    textAlign: "center",
                    marginRight: "4px",
                  }}
                  to={`/category/${node.slug}`}
                >
                  <div
                    style={{
                      fontFamily: "Baloo Bhaina 2",
                    }}
                  >
                    {node.title}
                  </div>
                </Link>
              ))}
            {post.date}
          </div>
        </header>
        <BlogPostContent content={post.content.document} />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link
                to={previous.slug}
                rel="prev"
                style={{
                  color: "#948f47",
                  fontFamily: "Baloo Bhaina 2",
                }}
              >
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.slug}
                rel="next"
                style={{
                  color: "#948f47",
                  fontFamily: "Baloo Bhaina 2",
                }}
              >
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <hr />
      <ShareBlock post={post} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      title
      subtitle
      author
      date
      category {
        title
        slug
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      content {
        document: json
      }
    }
  }
`
