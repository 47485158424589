import React from "react"

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import "../css/typography.css"

const BoldText = styled.span`
  font-weight: 800;
  font-family: "Baloo Bhaina 2";
`
const ItalicText = styled.span`
  font-style: italic;
  font-family: "Baloo Bhaina 2";
`
const UnderlineText = styled.span`
  text-decoration: underline;
  font-family: "Baloo Bhaina 2";
`
const PostText = styled.div`
  font-family: "Baloo Bhaina 2";
`

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <BoldText>{text}</BoldText>,
    [MARKS.ITALIC]: text => <ItalicText>{text}</ItalicText>,
    [MARKS.UNDERLINE]: text => <UnderlineText>{text}</UnderlineText>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a
          style={{
            color: "#948f47",
            boxShadow: "none",
            textDecoration: "underline",
          }}
          href={node.data.uri}
        >
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1
        style={{
          color: "#3e5336",
          fontFamily: "Girassol",
        }}
      >
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2
        style={{
          color: "#3e5336",
          fontFamily: "Girassol",
        }}
      >
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3
        style={{
          color: "#3e5336",
          fontFamily: "Girassol",
        }}
      >
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4
        style={{
          color: "#3e5336",
          fontFamily: "Girassol",
        }}
      >
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5
        style={{
          color: "#3e5336",
          fontFamily: "Girassol",
        }}
      >
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6
        style={{
          color: "#3e5336",
          fontFamily: "Girassol",
        }}
      >
        {children}
      </h6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul style={{ margin: "10px 20px" }}>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol style={{ margin: "10px 20px" }}>{children}</ol>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <img
        src={node.data.target.fields.file["en-US"].url}
        alt={node.data.target.fields.file["en-US"].description}
      />
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <PostText>{children}</PostText>,
  },
}

const BlogPostContent = content => (
  <div style={{ marginBottom: "20px" }}>
    {documentToReactComponents(content.content, options)}
  </div>
)

export default BlogPostContent
