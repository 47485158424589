import React from "react"
import {
  FaTwitter,
  FaFacebook,
  FaEnvelope,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa"
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share"

const ShareBlock = ({ post: { slug, title, subtitle, image } }) => {
  const src = image
    ? image.fluid.src
    : "https://everythinglucia.com/static/profile-pic-76233c178f5ed83078a06276c972c32b.jpeg"
  const shareBlockProps = {
    url: `https://everythinglucia.com/${slug}`,
    button: ShareButtonRectangle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "Email", icon: FaEnvelope },
      {
        network: "Pinterest",
        icon: FaPinterest,
        media: `${src}`,
      },
      { network: "Linkedin", icon: FaLinkedin },
    ],
    text: `${title}`,
    longtext: `${subtitle}`,
  }
  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <ShareBlockStandard {...shareBlockProps} />
    </div>
  )
}

export default ShareBlock
